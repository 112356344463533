<template>
  <section>
    <!--<pm-Breadcrumb :home="home" :model="items_bread" />-->

    <div class="row">
      <div class="col-md-6">
        <h2 class="titulo-links-escolha">Coordenadores</h2>
      </div>
      <div class="col-md-6"  v-if="situacaoAno">

        <pm-Button type="button" style="float: right;margin-top: 30px;"
          @click="registar()" label="CADASTRAR COORDENADOR"
          class="p-button-success"
        ></pm-Button>
      </div>
    </div>


    <div class="pm-card">
      <pm-DataTable :value="professorALL" id="servidores"  dataKey="id" :rowHover="true" filterDisplay="menu" v-model:filters="filters"
        :globalFilterFields="['nome']" responsiveLayout="scroll">

        <template #header>
          <div class="p-fluid formgrid grid" style="font-size:12px">
            <div class="field col-12 md:col-4 lg:col-3" style="margin:0px;">
              <label style="font-weight: 400 !important;">Função:</label>
              <p>Coordenador</p>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin:0px;">
              <label style="font-weight: 400 !important;margin-top: 15px;">  </label>
              <span class="p-input-icon-left ">
                <i class="pi pi-search" />
                <pm-InputText v-model="pesquisa" @input="pesquisar" placeholder="Pesquisar Nome do Servidor"/>
              </span>
            </div>
          </div>
        </template>

        <pm-Column v-for="col of items"  sortable :field="col.key" :header="col.label" :key="col.key" style="font-size: 14px;vertical-align:middle !important">

          <template #body="{data}" >
            <div v-if="col.key == 'foto'">
              <div class="tb-center"  v-if="data.id.foto" >
                <img v-if="data.id.foto" class="img-perfil-dtb-pq" :src="base_url+'/storage/'+data.id.foto" />
              </div>
              <div class="tb-center"  v-if="data.id.foto == null" >
                <pm-Avatar icon="pi pi-user" class="mr-2" style="background-color:#e1e1e1; color: #ffffff" shape="circle" />

              </div>

            </div>
            <div v-if="col.key == 'nome'">
              {{data.nome+' '}}
              <b v-if="data.sobrenome" style="font-weight: 400;">{{data.sobrenome}}</b>
                 &nbsp;
              <span class="badge bg-danger table_style" v-if="data.deficiencia == 1" :title="data.qual_deficiencia">NEE</span>

            </div>
            <div v-if="col.key == 'funcao'">
              {{data.funcao}}
            </div>
            <div v-if="col.key == 'opcoes'">
              <div v-if="data.opcoes.funcao_id == 5">
                <pm-InputSwitch class="small-switch" v-model="status[data.opcoes.index]" @click="mudarStatus(data.opcoes)"/>
              </div>
            </div>

            <div v-if="col.key == 'escola_nome'">
              <div v-for="n in data.escola_nome" :key="n"><b>{{n.nome}} </b></div>
            </div>
            <div v-if="col.key == 'escola_nome_diretor'">
              <div><b>{{data.escola_nome_diretor}} </b></div>
            </div>
            <div v-if="col.key == 'escola_todo'">
              <div v-for="n in data.escola_todo.escola_nome" :key="n"><b>{{n.nome}} </b></div>
              <div><b>{{data.escola_todo.escola_nome_diretor}} </b></div>
            </div>
            <div v-if="col.key == 'segmentos'">
              <div v-if="data.segmentos.pre_escola == 1"><b style="color: blue;"> Pre Escola </b></div>
              <div v-if="data.segmentos.fundamental1 == 1"><b style="color: blue;"> Fundamental I </b></div>
              <div v-if="data.segmentos.fundamental2 == 1"><b style="color: blue;"> Fundamental II </b></div>
              <div v-if="data.segmentos.ern == 1"><b style="color: blue;"> ERN </b></div>
              <div v-if="data.segmentos.eja1 == 1"><b style="color: blue;"> EJA ( 2 fase até a 5 fase) </b></div>
              <div v-if="data.segmentos.eja2 == 1"><b style="color: blue;"> EJA ( 6 fase até a 9 fase) </b></div>
            </div>
            <div v-if="col.key == 'id'">
              <va-button-dropdown class="ml-2" color="#efefef" :rounded="false" v-if="situacaoAno">
                <template #label>
                  <h5 style="font-size: 15px;color:#a2a5b9;"> Ações</h5>
                </template>
                <div>
                  <va-list>
                    <va-list-item  @click="editarSegmento(data.id.id)" style="cursor: pointer;">
                      <va-list-item-section icon>
                        <va-icon
                          name="create"
                          color="gray"
                        />
                      </va-list-item-section>
                      <va-list-item-section>
                        <va-list-item-label>
                          Edição
                        </va-list-item-label>

                        <va-list-item-label caption>
                          Servidor
                        </va-list-item-label>
                      </va-list-item-section>
                    </va-list-item>




                    <!--Coordenador-->
                    <div v-if="data.id.coordenador">
                      <div v-if="data.id.coordenador.funcao_id == 4">

                        <va-list-item  v-if="!data.id.paraEditarCord" @click="registarCoordenador()" style="cursor: pointer;" >
                          <va-list-item-section icon>
                            <va-icon
                              name="add_box"
                              color="gray"
                            />
                          </va-list-item-section>
                          <va-list-item-section>
                            <va-list-item-label>
                              Adicionar ao Ano Letivo
                            </va-list-item-label>

                            <va-list-item-label caption>
                              Coordenador
                            </va-list-item-label>
                          </va-list-item-section>
                        </va-list-item>

                        <va-list-item  v-if="data.id.paraEditarCord" @click="editarCorde(data.id.coordenador.id_coordenador)" style="cursor: pointer;" >
                          <va-list-item-section icon>
                            <va-icon
                              name="create"
                              color="gray"
                            />
                          </va-list-item-section>
                          <va-list-item-section>
                            <va-list-item-label>
                              Editar
                            </va-list-item-label>

                            <va-list-item-label caption>
                              Coordenador
                            </va-list-item-label>
                          </va-list-item-section>
                        </va-list-item>

                        <va-list-item  v-if="data.id.paraEditarCord"  @click="deletarCorde(data.id.coordenador.id_coordenador)" style="cursor: pointer;" >
                          <va-list-item-section icon>
                            <va-icon
                              name="group_work"
                              color="gray"
                            />
                          </va-list-item-section>
                          <va-list-item-section>
                            <va-list-item-label>
                              Deletar
                            </va-list-item-label>

                            <va-list-item-label caption>
                              Coordenador
                            </va-list-item-label>
                          </va-list-item-section>
                        </va-list-item>
                      </div>
                    </div>
                  </va-list>
                </div>
              </va-button-dropdown>
            </div>

          </template>

        </pm-Column>

      </pm-DataTable>
      <div class="p-paginator p-component p-paginator-bottom" >
        <div class="p-paginator-pages" style="display: inline-flex;">
          <div  v-for="n in links" :key="n">
            <button class="p-paginator-next p-paginator-element p-link" @click="paginas(n)" v-if="!n.active"
              style="border: 0px solid #ebebeb;border-radius: 0;">
              <p class="p-paginator-icon " v-html="n.label"  style="margin-bottom:0px !important;"></p>
            </button>
            <button class="p-paginator-page p-paginator-element p-link p-highlight" @click="paginas(n)" v-if="n.active" style="border-radius: 0;">{{n.label}}</button>
          </div>
        </div>
      </div>
    </div>


    <va-modal
      v-model="showModal"
      hide-default-actions
      overlay-opacity="0.2"
    >
      <slot>
        <div>
          <img :src="foto_grande" style="width:400px;" />
        </div>
      </slot>
    </va-modal>

  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Servidores } from "@/class/servidores.js";
import { Professor } from "@/class/professor.js";
import { Calendario } from "@/class/calendario.js";
import { Turma } from "@/class/turma.js";
import { Coordenador } from "@/class/coordenador.js";
import { Diretor } from "@/class/diretor.js";

import { DisciplinaEscolar } from "@/class/disciplina.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { Configuracao } from "@/class/configuracao.js";
import { Escola } from "@/class/escolas.js";
import axios from 'axios';

import { Funcao } from "@/class/funcao.js";

export default defineComponent({
  data() {
    const columns = [
      { key: "codigo_INEP", label:"Codigo INEP" },
      { key: "nome", label: "Nome", sortable: true },
      { key: "op", label: "Configuracão Anual "},
      { key: "id", label: "Ação" },
    ];


    return {
      base_url: axios.defaults.baseURL.replace('/api/v1',''),
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Servidores'},
      ],
      items: [],
      faseSelecionada:'',
      radioSelectedOption:"option1",
      ehFundamentalI:0,
      situacaoAno:1,
      filter: '',
      showModal:false,
      foto_grande:null,
      showMediumModal: false,
      showDisciplinaModal: false,
      showgerenciarHorarioModal:false,
      showgerenciarDisciplinaModal: false,
      tipo:1,
      ano:0,
      info: [],

      servidores: 0,

      id_item:0,
      segmentoSelecionado:'',
      disciplinaSelecionada:'',
      professorSelecionado:'',
      escolaSelecionada:1,
      turnoSelecionado:'',

      disabledSelect:false,
      okParaEdicao:0,
      status: [],
      disciplinaALL:[],
      situacaoAno:1,
      showMediumModal: false,
      filter1: '',
      filter2: '',
      tituloModal:'',
      textoModal:'',
      servidoresEscolarALL: [],
      links:[],
      professorALL: [],
      turnosDeTrabalho:[],

      perPage1: 20,
      perPage2: 20,
      currentPage1: 1,
      currentPage2: 1,
      TheAno:0,
      seg_tipo:0,
      pesquisa:null,
    };
  },
  computed: {
    pages1() {
      return this.perPage1 && this.perPage1 !== "0"
        ? Math.ceil(this.servidoresEscolarALL.length / this.perPage1)
        : this.servidoresEscolarALL.length;
    },
    pages2() {
      return this.perPage2 && this.perPage2 !== "0"
        ? Math.ceil(this.professorALL.length / this.perPage2)
        : this.professorALL.length;
    },
  },
  methods: {
    fotoOpen(foto,sexo){
      //quando a foto vier Null eu mando os Sexos 'F' ou 'M'
      //caso nao tiver null eu mando um 'A'

      if(sexo == 'A'){
        this.foto_grande = this.base_url+'/storage/'+foto;
        this.showModal = true;
      }
    },

    async paginas(n){
      if(n.url != null){
        if(!n.active){
          /*let res = await axios.get(n.url);
          this.turmasALL =[];
          this.links = res.data.links;*/
          this.listarTudo(n);
        }
      }
    },

    async pesquisar(){
      try{
      if(this.pesquisa != null && this.pesquisa != ""){
        const data = await Servidores.pesqServidorCompleto(this.pesquisa);
        this.links = [];

        for (const el of data.data) {
              if(el.funcao == 5){
                funcao_dele = "Professor";
              }else if(el.funcao == 4){
                funcao_dele = "Coodernador";
              }
              else if (el.funcao == 3) {
                funcao_dele = "Diretor";
              }
              else{
                funcao_dele = "Gestor";
              }

          const columns = [
            { key: "foto", label:"FOTO DE PERFIL" },
            { key: "nome", label: "NOME", sortable: true },
            { key: "funcao", label: "FUNÇÃO", sortable: true },
            { key: "escola_todo", label: "ESCOLAS (COORDENADOR)"},
            { key: "segmentos", label: "SEGMENTOS COORDENADOR"},

            { key: "id", label: "Ação Servidor" },
          ];

          this.items = [];
          this.items = columns;
          let funcao_dele ="", index = 0;
          this.status = [];
          this.professorALL = [];

          const novo = {
            id:{id: el.id,
            foto:el.foto,
            sexo:el.sexo,
            op:{
            index:index,
            id: el.id,
            ativo: el.habilitado_no_ano,
            funcao_id:el.funcao,
            },
            info:{
              id:el.id,
            },
            coordenador:{
              id_coordenador:el.id,
              funcao_id:el.funcao,
            },
            diretor:{
              id: el.diretor.id,
              servidor_id: el.diretor.servidor_id,
              ano: el.diretor.ano,
              escola: el.diretor.escola,
              funcao_id:el.funcao,
            },
            paraEditarCord: el.escola_nome.length > 0,
            paraEditarDire: el.diretor.escola_nome != undefined
            },
            codigo_INEP: el.codigo_INEP,
            nome:el.nome,
            funcao_id:el.funcao,
            funcao: funcao_dele,
            info:{
              id:el.id,
              nome: el.nome,
              funcao_id:el.funcao
            },
            funcao_id:el.funcao,
            opcoes: {
              index: index,
              id: el.id,
              funcao_id:el.funcao,
              ativo: el.habilitado_no_ano,
            },
            escola_nome: el.escola_nome,
            op:{
              index:index,
              id: el.id,
              ativo: el.habilitado_no_ano,
              funcao_id:el.funcao,
            },
            coordenador:{
              id_coordenador:el.id,
              funcao_id:el.funcao,
            },
            diretor:{
              id: el.diretor.id,
              servidor_id: el.diretor.servidor_id,
              ano: el.diretor.ano,
              escola: el.diretor.escola,
              funcao_id:el.funcao,
            },
            escola_nome_diretor: el.diretor.escola_nome,
            escola_todo:{
              escola_nome_diretor: el.diretor.escola_nome,
              escola_nome: el.escola_nome,
            },
            segmentos:{
              pre_escola: el.pre_escola,
              fundamental1: el.fundamental1,
              fundamental2: el.fundamental2,
              eja1: el.eja1,
              eja2: el.eja2,
              ern: el.ern,
            }
          };
          index++;
          el.habilitado_no_ano  = el.habilitado_no_ano ? true : false;
          this.status.push(el.habilitado_no_ano);

          this.professorALL.push(novo);
        }

      }else{
         this.listarTudo(1);
      }
      }catch(e){
        // console.log(e)
        }
    },



    registar() {
      this.$router.push({ name: "servidor-basic-info-cadastrar" });
    },



    async listarTudo() {
     /* try {
        this.professorALL = [];
        let data = [];
        if(1 == 1){
          //let res = await Servidores.obtemTodos();
          let res = await Servidores.obtemTodosTipo(this.servidores);
          data = res.data.data;
          this.links = res.data.links;
        }else{
          let res = await axios.get(n.url);
          data = res.data.data;
          this.links = res.data.links;
        }
        let funcao_dele ="", index = 0;
        this.status = [];
        this.professorALL = [];
        const columns = [
          { key: "foto", label:"FOTO DE PERFIL" },
          { key: "nome", label: "NOME", sortable: true },
          { key: "funcao", label: "FUNÇÃO", sortable: true },
          { key: "escola_nome", label: "ESCOLAS DO COORDENADOR"},
          { key: "segmentos", label: "SEGMENTOS"},
          { key: "id", label: " " },
        ];

        for (const el of data) {
          if(el.funcao == 4){
            funcao_dele = "Coodernador";
          }

          const columns = [
            { key: "foto", label:"FOTO DE PERFIL" },
            { key: "nome", label: "NOME", sortable: true },
            { key: "funcao", label: "FUNÇÃO", sortable: true },
            { key: "escola_nome", label: "ESCOLAS DO COORDENADOR"},
            { key: "segmentos", label: "SEGMENTOS"},
            //{ key: "coordenador", label: "Ação Coordenador"},
            { key: "id", label: " " },
          ];

          this.items = [];
          this.items = columns;
          let novo = {
            id:{id: el.id,
            foto:el.foto,
            sexo:el.sexo,
              op:{
                index:index,
                id: el.id,
                ativo: el.habilitado_no_ano,
                funcao_id:el.funcao,
              },
              info:{
                id:el.id,
              },
              coordenador:{
                id_coordenador:el.id,
                funcao_id:el.funcao,
              },
              paraEditarCord: el.escola_nome.length > 0,

            },
            nome:el.nome,
            sobrenome:el.sobrenome,
            deficiencia:el.deficiencia,
            qual_deficiencia:el.qual_deficiencia,
            funcao_id:el.funcao,
            funcao: funcao_dele,
            info:{
              id:el.id,
              nome: el.nome,
              funcao_id:el.funcao
            },
            funcao_id:el.funcao,
            opcoes: {
              index: index,
              id: el.id,
              funcao_id:el.funcao,
              ativo: el.habilitado_no_ano,
            },
            escola_nome: el.escola_nome,
            op:{
              index:index,
              id: el.id,
              ativo: el.habilitado_no_ano,
              funcao_id:el.funcao,
            },
            coordenador:{
              id_coordenador:el.id,
              funcao_id:el.funcao,
            },
            segmentos:{
              pre_escola: el.pre_escola,
              fundamental1: el.fundamental1,
              fundamental2: el.fundamental2,
              eja1: el.eja1,
              eja2: el.eja2,
              ern: el.ern,
            }
        }
        index++;
        el.habilitado_no_ano  = el.habilitado_no_ano ? true : false;
        this.status.push(el.habilitado_no_ano);
        this.professorALL.push(novo);
        this.professorALL.reverse();
        //this.servidoresEscolarALL.reverse();
      }
       */
    },

    async deletarCorde(id) {
      if (window.confirm("Você realmente deseja remover está pessoa da coordenação?")) {
        try{
          const data1 = await Coordenador.obtemUmServidor(id);
          const data = await Coordenador.remover(data1.data.id);
          this.$vaToast.init({
              message: "Removido com sucesso!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 2500,
              fullWidth: false,
              color:'success'
          });
          this.listarTudo(1);
          }catch(e){
            const a = e.response.data.erro;
            this.$vaToast.init({
              message: a,
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 2500,
              fullWidth: false,
              color:'#e42222'
            })
          }
      }
    },
    editarCorde(id){
      this.$router.push({ name: "coordenadoresedit", params: { id: id}});
    },
       registarCoordenador() {
      this.$router.push({ name: "coordenadoresadd" });
    },

  },
  async  beforeMount() {
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    this.listarTudo();
    const calendario = await Calendario.calendarioAtual();
    this.TheAno = calendario.data.ano;
    const anoSele =  parseInt(sessionStorage.getItem("anoSelecionado"));
    this.okParaEdicao = (anoSele == this.TheAno);
  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>

<style>
 .va-list-item:hover{
   background-color: #f5f5f5;
 }
 .va-list-item__inner{
   padding-right: 30px;
 }
 .va-dropdown__content{
   padding: 0;
 }
 .form-control{
  background-color: #fff !important;
 }
</style>
